.timeline {
    align-items: center;
    justify-content: center;
  }
  .avatarCenter {
    display: flex;
    justify-content: center;
  }
  
  .avatarWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .floating-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
  }
  
  .floating-button .main-btn {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: white;
    border: none;
    cursor: pointer;
    /* transition: transform 3.3s ease; */
  }
  
  .floating-button .options {
    position: absolute;
    bottom: 70px;
    right: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease, visibility 0.5s ease;
  }
/*   
  .floating-button.expanded .main-btn {
    transform: translateY(-50px);
  } */
  
  .floating-button.expanded .options {
    opacity: 1;
    visibility: visible;
  }
  
  .floating-button button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    border: none;
    cursor: pointer;
  }
  .options .wsap {
    background-color: white;
  }
  
 .options .linkedin {
    background-color: white;
  }
  